<template>
  <div class="lottery-analyse">
    <div class="header">
      <div class="title-box">
        <p class="title">冷热分析</p>
        <intro-default cont="热码，指近出现频繁、活跃的号码；冷码，指近出现频率低甚至没有出现的号码；温码，指介于冷热之间的号码。"></intro-default>
      </div>
      <div class="tool-box">
        <select-user style="margin-right: 20px" type="1" @change="page_change"></select-user>
        <div class="item show-time">
          <el-checkbox v-model="showTime">显示号码次数</el-checkbox>
        </div>
        <div class="item refresh-time">
          <div class="refresh" @click="requestGetHistory">手动刷新</div>
        </div>
      </div>
    </div>
    <!-- <div class="table">
      <el-table :data="tableData" border style="width: 100%" highlight-current-row header-cell-class-name="table-header-cell" cell-class-name="table-cell" row-class-name="table-row" size="small" row-key="id" ref="table" id="table">
        <el-table-column prop="rank" label="名次" width="120" align="center"></el-table-column>
        <el-table-column align="center" width="290" label="热">
          <template #default="scope">
            <div class="numbers">
              <number class="number" v-for="number in scope.row.hot" :key="number.value" :value="number.value" :badge="number.time" :showBadge="showTime" :lottery-code="code"></number>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="温">
          <template #default="scope">
            <div class="numbers">
              <number class="number" v-for="number in scope.row.warm" :key="number.value" :value="number.value" :badge="number.time" :showBadge="showTime" :lottery-code="code"></number>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" width="290" label="冷">
          <template #default="scope">
            <div class="numbers">
              <number class="number" v-for="number in scope.row.cold" :key="number.value" :value="number.value" :badge="number.time" :showBadge="showTime" :lottery-code="code"></number>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div> -->
    <div class="lz_cont">
      <div class="table"
        id="table">
        <!-- :width="tableWidth" -->
        <table cellpadding="0"
          cellspacing="0"
          class="table-data"
          ref="table">
          <thead class="table-header-cell">
            <tr>
              <th scope="col" style="min-width: 120px; width: 120px;">名次</th>
              <th scope="col">
                <div class="table_rwl">
                  <div>热</div>
                  <div>温</div>
                  <div>冷</div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr height="50" v-for="row in tableData" :key="row.expect">
              <td>{{ row.rank }}</td>
              <td>
                <div class="numbers_box">
                  <div class="numbers">
                    <div v-for="number in row" :key="number.value" class="number_line">
                      <number class="number" :value="number.value" :badge="number.time" :showBadge="showTime" :lottery-code="code"></number>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Loading, Checkbox, Select, Option } from 'element-ui'
import Number from '@components/pc/Number'

export default {
  name: 'LotteryAnalyse',
  props: {
    code: String
  },
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Checkbox.name]: Checkbox,
    [Select.name]: Select,
    [Option.name]: Option,
    // [Popover.name]: Popover,
    [Number.name]: Number
  },
  data () {
    return {
      per_page: 10,
      showTime: true,
      data: [],
      loading: null,
      counts: [
        {
          label: '20期',
          value: 20
        },
        {
          label: '50期',
          value: 50
        },
        {
          label: '100期',
          value: 100
        }
      ],
      ranks: [],
      countValue: 20,
      refreshDate: Date.now()
    }
  },
  computed: {
    tableData () {
      var data = []
      for (let i = 0; i < this.data.length && i < this.ranks.length; i++) {
        var element = this.data[i]
        element.rank = this.ranks[i]
        data.push(element)
      }
      return data
    }
  },
  watch: {
    code: function (val, oldVal) {
      this.per_page = 10
      this.requestGetHistory()
    },
    countValue: function (val, oldVal) {
      this.requestGetHistory()
    }
  },
  mounted () {
    this.requestGetHistory()
  },
  methods: {
    page_change (value) {
      this.per_page = value
      this.requestGetHistory()
    },
    refresh () {
      this.requestGetHistory()
    },
    get_rank () {
      this.ranks = this.get_type_name(this.code, '任选一')
      console.log(this.ranks)
    },
    requestGetHistory () {
      this.refreshDate = Date.now()
      this.showTableLoading()
      this.$api.hotCold(this.code, this.per_page).then((res) => {
        this.get_rank()
        this.data = res.data
        this.hideTableLoading()
      }).catch((err) => {
        this.hideTableLoading()
        this.$message.error(err)
      })
    },
    showTableLoading () {
      if (this.loading) return
      this.loading = Loading.service({
        target: '#table',
        fullscreen: false,
        background: 'rgba(0, 0, 0, 0)'
      })
    },
    hideTableLoading () {
      this.$nextTick(() => {
        this.loading.close()
        this.loading = null
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@scss/pc/variables';
.lottery-analyse {
  > .header {
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    border-bottom: thin $color-border solid;

    > .title-box {
      display: flex;
      align-items: center;
      height: 49px;

      > .title {
        font-size: 20px;
        font-weight: 400;
        color: #222;
      }
    }

    > .tool-box {
      display: flex;
      align-items: center;

      > .item {
        font-size: 14px;
        color: #222;
        position: relative;
      }

      > .show-count {
          margin-right: 20px;
          display: flex;
          align-items: center;
          >p {
            margin-right: 14px;
          }
          >div {
            width: 150px;
          }
      }

      > .refresh-time {
        margin-left: 20px;
        display: flex;
        align-items: center;
        >p {
          color: #959595;
          >span {
            margin-left: 9px;
            color: #222;
          }
        }
        > .refresh {
          cursor: pointer;
          width: 80px;
          height: 28px;
          background: #F9F9F9;
          border: 1px solid #E3E3E6;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-weight: 400;
          color: #222;
          margin-left: 10px;
        }
      }
    }
  }

  .lz_cont {
    padding: 20px;
    padding-top: 30px;
    padding-bottom: 0;
    box-sizing: border-box;
  }

  .table {
    position: relative;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    > .table-data {
      table-layout: fixed;
      word-break: break-all;
      word-wrap: break-word;
      border-collapse: separate;
      border: 1px solid #d6d6d6;
      border-bottom: none;
      border-right: none;
      // position: sticky;
      width: auto;
      min-width: 100%;

      .el-table thead {
        color: #909399;
        font-weight: bold;
      }

      td,
      th {
        min-width: 0;
        box-sizing: border-box;
        text-overflow: ellipsis;
        vertical-align: middle;
        position: relative;
        text-align: center;
        border-bottom: 1px solid #d6d6d6;
        border-right: 1px solid #d6d6d6;
        font-size: 14px;
      }

      th {
        height: 37px;
        font-weight: bold;
        background-color: #f9f9f9;
      }

      td {
        color: rgba(0, 0, 0, 0.8);
      }
    }

    .numbers {
      height: 50px;
      min-width: 100%;
      display: flex;
      >div {
        flex: 1;
        min-width: 48px;
        height: 100%;
        border-right: 1px solid #d6d6d6;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &:last-child {
        >div:last-child {
          border-right: none;
        }
      }
      .number {
        flex-shrink: 0;
      }
    }
  }
}
.numbers_box {
  display: flex;
  align-items: center;
}
.table_rwl {
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #FF9A7F 0%, #FDFF7F 54%, #7FE8FF 100%);
  display: flex;
  align-items: center;
  >div:nth-child(1) {
    margin-left: 140px;
  }
  >div:nth-child(2) {
    flex: 1;
    text-align: center;
  }
  >div:nth-child(3) {
    margin-right: 130px;
  }
}
</style>

<style lang="scss">
.show-count .el-input--small .el-input__inner {
  height: 28px !important;
  line-height: 28px !important;
}
.show-count .el-input--small .el-input__icon {
  line-height: 28px !important;
}
</style>
